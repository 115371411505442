import { gql } from '@apollo/client';

export const fetchIndividualProfileDetails = gql`
  query getIndividualProfileDetails($MemberId: String!) {
    getIndividualProfileDetails(MemberId: $MemberId) {
      Individual {
        MemberId
        Prefix
        FirstName
        MiddleName
        LastName
        Suffix
        BadgeName
        LocalizedName
        Gender
        DateOfBirth
        PrimaryLanguage
        Classification
        YouthFlag
        GenderDescription
        Key
        LastUpdated
      }
      Address {
        AddressType
        AddressLine1
        AddressLine2
        AddressLine3
        Country
        State
        InternationalProvince
        City
        PostalCode
        MemberId
        IsPrimary
        Key
        LastUpdated
      }
      Phone {
        MemberId
        PhoneType
        PhoneNumber
        PhoneExtension
        CountryCode
        PhoneCountryName
        IsPrimary
        Key
        LastUpdated
      }
      Email {
        EmailAddress
        EmailType
        IsPrimary
        MemberId
        IsOnlineId
        Key
        LastUpdated
      }
      Language {
        IsPrimary
        Key
        LanguageName
        LastUpdated
        MemberId
        Proficiency
        Read
        Speak
      }
    }
  }
`;
