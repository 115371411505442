import {
  FormattedProfileDetails,
  Profile,
} from '@typings/loggedInUserDetailsForOG';

export const getTransformedProfile = (
  profiledetails: Profile
): FormattedProfileDetails => {
  const activephone = profiledetails.Phone.filter(phone => {
    return phone.IsPrimary === true;
  });

  const activeemail = profiledetails.Email.filter(email => {
    return email.IsPrimary === true;
  });

  const activeaddress = profiledetails.Address.filter(address => {
    return address.IsPrimary === true;
  });

  const userData = {
    authenticated: !!profiledetails.Individual.MemberId,
    member_id: profiledetails.Individual.MemberId,
    first_name: profiledetails.Individual.FirstName,
    last_name: profiledetails.Individual.LastName,
    localized_name: profiledetails.Individual.LocalizedName,
    phone: activephone.length ? activephone[0].PhoneNumber : '',
    email: activeemail.length ? activeemail[0].EmailAddress : '',
  };

  const primaryAddress = activeaddress.length
    ? {
        line1: activeaddress[0].AddressLine1,
        line2: activeaddress[0].AddressLine2,
        line3: activeaddress[0].AddressLine3,
        city: activeaddress[0].City,
        state: activeaddress[0].State,
        zip: activeaddress[0].PostalCode,
        intl_province: activeaddress[0].InternationalProvince,
        country: activeaddress[0].Country,
      }
    : null;

  return { ...userData, ...{ address: primaryAddress } };
};
