import { useEffect, useState } from 'react';

import { useGetIndividualProfileDetails } from './useGetIndividualProfileDetails';
import { useGetMemberOrganization } from './useGetMemberOrganization';

import { useGetIndividualSecurityGroups } from '@repositories/my-donations';

import { getTransformedProfile } from '@utils/getTransformedProfile';
import { getTransformedSecurityGroups } from '@utils/getTransformedSecurityGroups';

import { useAppConfig } from '@hooks/appConfig';

import {
  FormattedProfileDetails,
  IndividualSecurityGroupsResult,
  MemberOrganizationDetails,
  Profile,
  SecGroupOutput,
} from '@typings/loggedInUserDetailsForOG';

export const useSetUserIndividualDetails = () => {
  const [securityGroupsData, setSecurityGroupsData] = useState<
    SecGroupOutput[]
  >([]);

  const [loggedUserData, setLoggedUserData] = useState<FormattedProfileDetails>(
    null
  );

  const [organizationDetails, setOrganizationDetails] = useState<
    MemberOrganizationDetails
  >(null);

  const { user } = useAppConfig();
  const userIndividualId = (user?.isLoggedIn && user.riIndividualId) || '';

  const [fetchIndividualSecurityGroups] = useGetIndividualSecurityGroups();

  const [fetchIndividualProfileDetails] = useGetIndividualProfileDetails();

  const [fetchMemberOrganizationDetails] = useGetMemberOrganization();

  const fetchProfileDetails = async () => {
    const { data } = await fetchIndividualProfileDetails({
      variables: {
        MemberId: userIndividualId,
      },
    });

    const formattedProfile = getTransformedProfile(
      data?.getIndividualProfileDetails as Profile
    );

    setLoggedUserData(formattedProfile);
  };

  const fetchSecurityGroups = async () => {
    const { data } = await fetchIndividualSecurityGroups({
      variables: {
        memberId: userIndividualId,
      },
    });
    const secGroups = data?.securityGroups as IndividualSecurityGroupsResult[];
    const userData = {
      sec_groups: getTransformedSecurityGroups(secGroups),
    };
    setSecurityGroupsData(userData.sec_groups);
  };

  const fetchMemebrOrgDetails = async () => {
    const { data } = await fetchMemberOrganizationDetails({
      variables: {
        MemberId: userIndividualId,
      },
    });
    setOrganizationDetails(
      data?.getMemberOrganizationDetails as MemberOrganizationDetails
    );
  };

  useEffect(() => {
    if (userIndividualId.length) {
      fetchSecurityGroups();
      fetchProfileDetails();
      fetchMemebrOrgDetails();
    }
  }, [userIndividualId]);

  useEffect(() => {
    if (!userIndividualId.length) {
      window.localStorage.setItem('user', '');
    } else {
      window.localStorage.setItem(
        'user',
        JSON.stringify({
          ...{ sec_groups: securityGroupsData },
          ...{
            ...loggedUserData,
            ...{
              club_type: organizationDetails?.CreditOrg.Type,
              prime_club_id: organizationDetails?.CreditOrg.Id,
            },
          },
        })
      );
    }
  }, [securityGroupsData, loggedUserData, organizationDetails]);
};
