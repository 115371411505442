import { gql } from '@apollo/client';

export const fetchMemberOrganizationDetails = gql`
  query getMemberOrganizationDetails($MemberId: String!) {
    getMemberOrganizationDetails(MemberId: $MemberId) {
      CreditOrg {
        Id
        Type
      }
    }
  }
`;
