import { useLazyQuery } from '@apollo/client';

import { fetchMemberOrganizationDetails } from '../queries/fetchMemberOrganizations';

import {
  GetMemberOrganizationDetailsQuery,
  GetMemberOrganizationDetailsQueryVariables,
} from '@typings/operations';

export const useGetMemberOrganization = () =>
  useLazyQuery<
    GetMemberOrganizationDetailsQuery,
    GetMemberOrganizationDetailsQueryVariables
  >(fetchMemberOrganizationDetails);
