import { useLazyQuery } from '@apollo/client';

import { fetchIndividualProfileDetails } from '../queries/fetchIndividualProfileDetails';

import {
  GetIndividualProfileDetailsQuery,
  GetIndividualProfileDetailsQueryVariables,
} from '@typings/operations';

export const useGetIndividualProfileDetails = () =>
  useLazyQuery<
    GetIndividualProfileDetailsQuery,
    GetIndividualProfileDetailsQueryVariables
  >(fetchIndividualProfileDetails);
